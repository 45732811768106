<template>
  <!-- <img key="onlineImg" :src="imagesrc" alt="user-img" :width="avatarSize" :height="avatarSize" class="rounded-full shadow-md cursor-pointer block" /> -->
  <!-- <feather-icon v-if="isExist" color="success" icon="MessageSquareIcon" svg-classes="h-5 w-5 text-success" class="ml-2" /> -->
  <feather-icon v-if="isExist > 0" color="success" icon="MessageSquareIcon" svg-classes="h-5 w-5 text-success" class="ml-2" />
</template>

<script>
import { carData } from "../../store/api/car";

export default {
    data() {
        return {
          isExist : 0,
        };
    },
    props: {
        registrationId: {
            type: String,
            default: ""
        },
    },
    watch: {
        registrationId: {
            immediate: true,
            deep: true,
            async handler(newValue, oldValue) {
                if (newValue) {
                    await this.getRegistrationCommentCount();
                }
            }
        },
    },
    methods: {
        async getRegistrationCommentCount() {
            var returnData = await carData.SearchRegistrationCommentCount(this.registrationId);
            if (returnData) {
                this.isExist = returnData.data;
            }
        }
    }
};
</script>
